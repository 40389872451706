export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    cols: 6,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    cols: 6,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Status',
    model: null,
    options: [
      { value: null, label: 'All' },
      { value: 1, label: 'CREATED BY CHIEF' },
      { value: 2, label: 'SENT TO CEO' },
      { value: 3, label: 'APPROVED BY CEO' },
      { value: 4, label: 'REJECTED BY CEO' },
      { value: 5, label: 'STARTED BY RRHH' },
      { value: 6, label: 'CLOSED BY RRHH' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Module',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Shift',
    model: null,
    options: [
      { value: null, label: 'All' },
      { value: 1, label: 'MORNING' },
      { value: 2, label: 'AFTERNOON' },
      { value: 3, label: 'ROTATING' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
  },
];
