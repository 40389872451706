<template>
  <b-modal
    ref="modal-recruitment-applicants"
    size="xlg"
    title="Recruitment Applicants"
    :no-close-on-backdrop="true"
    :hide-footer="recruitment.id_status===5 && isTabRrhh ?false:true"
    @hide="closeModalRecruimentApplicants"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <tabler-icon
          icon="UsersIcon"
          size="20"
          class="mr-1"
        />
        <h4 class="m-0 p-0 text-white">
          Recruiment Process "{{ recruitment.rol_name }}"
        </h4>
      </div>
    </template>
    <div
      v-if="isTabRrhh"
      class="text-warning ml-2 my-2"
    >
      <feather-icon
        icon="UserIcon"
        size="20"
      />
      <strong> ADD APPLICANT </strong>
    </div>

    <b-card
      v-if="isTabRrhh"
      border-variant="secondary"
    >
      <validation-observer ref="observer">
        <div class="ml-1 row">
          <div class="col-md-4">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:255"
              name="firstName"
            >
              <b-form-input
                v-model="firstName"
                placeholder="First name"
                :state="errors.length > 0 ? false : null"
              />
              <small
                v-if="errors.length > 0"
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </div>

          <div class="col-md-4">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:255"
              name="LastName"
            >
              <b-form-input
                v-model="lastName"
                placeholder="Last name"
                :state="errors.length > 0 ? false : null"
              />
              <small
                v-if="errors.length > 0"
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </div>

          <div class="col-md-4">
            <b-form-file
              v-model="file"
              placeholder="Upload Employee's CV"
              accept=".docx, .pdf"

              @change="uploadDocument($event)"
            />
          </div>
        </div>

        <div class="ml-1 mt-1 row">
          <div class="col-md-4">
            <validation-provider
              v-slot="{ errors }"
              rules="max:20"
              name="file"
            >
              <b-form-input
                v-model="phone"
                v-mask="'#########'"
                placeholder="Phone"
                type="number"
                :state="errors.length > 0 ? false : null"
              /><small
                v-if="errors.length > 0"
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </div>
          <div class="col-md-4">
            <validation-provider
              v-slot="{ errors }"
              rules="email"
              name="file"
            >
              <b-form-input
                v-model="email"
                placeholder="Email"
                type="email"
                :state="errors.length > 0 ? false : null"
              /><small
                v-if="errors.length > 0"
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </div>
          <div class="col-md-4">
            <b-button
              variant="outline-warning"
              class="w-100"
              @click="registerNewApplicant()"
            >
              Register
              <feather-icon
                icon="SaveIcon"
                size="15"
              />
            </b-button>
          </div>
        </div>
      </validation-observer>
    </b-card>
    <b-container class="p-1">
      <div>
        <filter-slot
          :filter="filter"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="$refs['refTable'].refresh()"
        >
          <template #table>
            <b-table
              slot="table"
              ref="refTable"
              small
              no-provider-filtering
              :items="getApplicants"
              :fields="field"
              primary-key="id"
              table-class="text-nowrap"
              responsive="sm"
              show-empty
              sticky-header="70vh"
              :busy.sync="isBusy"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #cell(salary)="data">
                <span v-if="data.item.salary">
                  S/.
                  {{ data.item.salary | currency }}
                </span>
                <span v-else>
                  -
                </span>
              </template>
              <template #cell(files)="data">
                <div class="w-100">
                  <div>
                    <feather-icon
                      class="text-primary mr-2 cursor-pointer"
                      icon="FileIcon"
                      size="20"
                      @click="openModalFiles(data.item)"
                    />
                  </div>
                </div>
              </template>
              <template #cell(created_at)="data">
                <span>
                  <feather-icon
                    icon="CalendarIcon"
                    size="15"
                    style="color: #0090E7"
                  /> {{ data.item.created_at | myGlobal }}
                </span>
              </template>
              <!-- Actions -->
              <template #cell(actions)="data">
                <b-button
                  v-if="currentUser.user_id !== 1"
                  :id="'tooltip-modal'+data.item.id"
                  variant="outline-success"
                  size="sm"
                  style="padding:0.3em 0.5em"
                  @click="showTimeLine(data.item)"
                >
                  <close-open-icon
                    :key="isDarkSkin"
                    :index="data.item.id"
                    :color="isDarkSkin?'#fff':'#000'"
                  />
                </b-button>
              </template>
              <template #cell(more)="data">
                <b-button

                  :id="'tooltip-modal_more_information'+data.item.id"
                  variant="outline-success"
                  size="sm"
                  @click="openMoreInformationApplicantModal(data.item)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="15"
                  />
                  ADD
                </b-button>
              </template>
              <template #cell(status)="data">
                <!-- button con el status -->
                <b-button
                  v-if="isTabRrhh"
                  size="sm"
                  :variant="
                    [6, 7, 8, 9, 10].includes(data.item.id_status)
                      ? colorStatus(data.item.id_status)
                      : 'outline-primary'
                  "
                  @click="openModalStatus(data.item)"
                >
                  {{ data.item.status.toUpperCase() }}
                </b-button>
                <b-button
                  v-if="isTabChief"
                  size="sm"
                  :variant="colorStatus(data.item.id_status)"
                  :disabled="
                    [6, 7, 8, 9, 10].includes(data.item.id_status)
                      ? false
                      : true
                  "
                  @click="openModalStatus(data.item)"
                >
                  {{ data.item.status.toUpperCase() }}
                </b-button>
                <b-button
                  v-if="isTabCeo"
                  size="sm"
                  :variant="
                    [6, 7, 8, 9, 10].includes(data.item.id_status)
                      ? colorStatus(data.item.id_status)
                      : 'outline-primary'
                  "
                  :disabled="true"
                  @click="openModalStatus(data.item)"
                >
                  {{ data.item.status.toUpperCase() }}
                </b-button>
              </template>

              <template #cell(tracking)="data">
                <tabler-icon
                  icon="ListIcon"
                  size="20"
                  class="text-info ml-1 cursor-pointer"
                  @click="openApplicantStatusTrackingModal(data)"
                />
              </template>
            </b-table>
          </template>
        </filter-slot>
      </div>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">
        Applicants currently working: {{ count_started_working }} of
        {{ recruitment.new_employee_quantity }}
        <b-button
          variant="success"
          class="ml-1"
          :disabled="readyCompleted ? false : true"
          @click="completedRecruitment"
        >
          Completed
          <tabler-icon
            icon="CircleCheckIcon"
            size="15"
          />
        </b-button>
      </div>
    </template>
    <modal-files-applicant
      v-if="showModalFilesApplicant"
      :recruitmen-tapplicant-id="recruitmenTapplicantId"
      :applicant="dataApplicant"
      @closeModalFilesApplicant="closeModalFilesApplicant"
    />
    <modal-status-applicant
      v-if="showModalStatusApplicant"
      :applicant="dataApplicant"
      :recruitment="recruitment"
      @closeModalStatusApplicant="closeModalStatusApplicant"
      @refreshTableApplicants="refreshTable"
    />
    <modal-applicant-status-tracking
      v-if="applicantStatusTrackingModal"
      :applicant="dataApplicant"
      @close="applicantStatusTrackingModal = false"
    />

    <time-line-individual
      v-if="showPostulantTimeline"
      :timeline-items="postulantData"
      :welcome-status="7"
      :status-name-position="statusNamePosition"
      :status-date-position="statusDatePosition"
      :wrapper-cords="wrapperCords"
      :postulant-name="postulantName"
      :back-url="imgsSrc.back"
      :flag-url="imgsSrc.flagUrl"
      :flag-position="flagPosition"
      :stop-icons-position="stopIconsPosition"
      :img-url="imgsSrc"
      :modal="true"
      class="timeline-individual"
      @closeIndividualTimeline="(global) => closeIndividualTimeline(global)"
      @showStageFiles="(item)=>showStageFiles(item)"
    />
    <modal-stage-files
      v-if="showModalStageFiles"
      :files="stageFiles"
      :stage-name="stageName"
      :applicant-name="postulantName"
      @closeModalFilesApplicant="showModalStageFiles = false"
    />
    <modal-more-information-employee
    v-if="modalMoreInformationApplicantModal"
    :applicant-data="applicantData"
    @refresh="refreshTable(0)"
    @close="modalMoreInformationApplicantModal=false"
    >

    </modal-more-information-employee>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

/*
* No quitar este import porfavor
*/

import style from '@haruka31dev/timeline/timeline.css';

import {
  TimeLineIndividual,
} from '@haruka31dev/timeline/timeline.common';
import filters from '@/views/commons/components/recruitment-process/data/filters.applicant.data';
import Service from '@/views/commons/components/recruitment-process/services/recruiment.service';
import FieldsApplicants from '@/views/commons/components/recruitment-process/data/fieldsApplicants.data';
import ModalFilesApplicant from '@/views/commons/components/recruitment-process/components/modals/ModalFilesApplicants.vue';
import ModalStatusApplicant from '@/views/commons/components/recruitment-process/components/modals/ModalStatusApplicant.vue';
import ModalApplicantStatusTracking
from '@/views/commons/components/recruitment-process/components/modals/ModalApplicantStatusTracking.vue';
import CloseOpenIcon from '@/views/commons/components/recruitment-process/components/CloseOpenIcon.vue';
import ModalStageFiles from '@/views/commons/components/recruitment-process/components/modals/ModalStageFiles.vue';
import ModalMoreInformationEmployee
  from "@/views/commons/components/recruitment-process/components/modals/ModalMoreInformationEmployee.vue";

export default {
  name: 'ModalRecruitmentApplicants',
  components: {
    ModalFilesApplicant,
    ModalStatusApplicant,
    ValidationObserver,
    ValidationProvider,
    ModalApplicantStatusTracking,
    CloseOpenIcon,
    TimeLineIndividual,
    ModalStageFiles,
    ModalMoreInformationEmployee,

  },
  props: {
    recruitment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      recruitmenTapplicantId: null,
      salary: null,
      field: FieldsApplicants,
      filter: filters,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Applicant\'s name...',
        model: '',
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: 'created_at',
      sortDesc: true,
      startPage: null,
      endPage: '',
      nextPage: '',
      toPage: null,
      isBusy: false,
      showModalFilesApplicant: false,
      showModalStatusApplicant: false,
      dataApplicant: null,

      // FORM ADD APPLICANT
      file: null,
      fileName: null,
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      count_started_working: null,
      applicantStatusTrackingModal: false,
      applicant_id: null,
      postulantData: [],
      postulantName: '',
      showPostulantTimeline: false,
      showModalStageFiles: false,
      backgroundUrl: {
        url: `${process.env.BASE_URL}assets/images/time-line/patternpad-5.svg`,
      },
      imgsSrc: {
        abilities: `${process.env.BASE_URL}assets/images/time-line/abilities.png`,
        autonomy: `${process.env.BASE_URL}assets/images/time-line/autonomy.png`,
        functions: `${process.env.BASE_URL}assets/images/time-line/functions.png`,
        requirements: `${process.env.BASE_URL}assets/images/time-line/requirements.png`,
        portada: `${process.env.BASE_URL}assets/images/time-line/portada.png`,
        arrow: `${process.env.BASE_URL}assets/images/time-line/arrow.png`,
        linkedin: `${process.env.BASE_URL}assets/images/time-line/linkedin.png`,
        facebook: `${process.env.BASE_URL}assets/images/time-line/facebook.png`,
        indeed: `${process.env.BASE_URL}assets/images/time-line/indeed.png`,
        logo: `${process.env.BASE_URL}assets/images/time-line/amg.svg`,
        info: `${process.env.BASE_URL}assets/images/time-line/info.png`,
        back: `${process.env.BASE_URL}assets/images/time-line/back.png`,
        continue: `${process.env.BASE_URL}assets/images/time-line/continue.svg`,
        stop: `${process.env.BASE_URL}assets/images/time-line/stop.svg`,
        flagUrl: `${process.env.BASE_URL}assets/images/time-line/flag-animation.png`,
      },
      statusNamePosition: {
        x: 0,
        y: 250,
      },
      statusDatePosition: {
        x: 0,
        y: 230,
      },
      wrapperCords: {
        top: 30,
        left: 100,
      },
      render: 0,
      flagPosition: {
        x: 30,
        y: 290,
      },
      stopIconsPosition: {
        x: 140,
        y: 50,
      },
      stageName: '',
      modalMoreInformationApplicantModal: false,
      applicantData: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    isTabChief() {
      return this.$route.meta.tab === 1;
    },
    isTabRrhh() {
      return this.$route.meta.tab === 2;
    },
    isTabCeo() {
      return this.$route.meta.tab === 3;
    },
    readyCompleted() {
      return this.count_started_working >= this.recruitment.new_employee_quantity;
    },
  },
  async mounted() {
    this.toggleModal('modal-recruitment-applicants');
    this.count_started_working = this.recruitment.count_started_working;
    await this.getStatus();
  },
  methods: {
    openMoreInformationApplicantModal(data) {
      this.modalMoreInformationApplicantModal = true;
      this.applicantData =data;
    },
    async showStageFiles(item) {
      try {
        const params = {
          applicant_status_id: item.applicant_status_id,
        };
        this.stageName = item.name;
        this.addPreloader();
        const data = await Service.getApplicantStageFiles(params);
        this.stageFiles = data.data.data;
        if (data.status === 200) {
          this.removePreloader();
          this.showModalStageFiles = true;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    async showTimeLine(item) {
      const params = {
        id_postulant: item.id,
        id_recruitment: this.recruitment.id,
      };
      const data = await Service.getPostulantData(params);
      this.postulantData = data.data;

      this.postulantName = item.name;
      this.showPostulantTimeline = true;
    },
    closeIndividualTimeline(global) {
      this.showPostulantTimeline = false;
    },
    openApplicantStatusTrackingModal(data) {
      this.applicantStatusTrackingModal = true;
      this.dataApplicant = data.item;
    },
    resetValues() {
      this.file = null;
      this.fileName = null;
      this.firstName = null;
      this.lastName = null;
      this.phone = 0;
      this.email = null;
    },

    colorStatus(idStatus) {
      switch (idStatus) {
        case 6:
          return 'outline-primary';
        case 7:
          return 'outline-warning';
        case 8:
          return 'outline-danger';
        case 9:
          return 'outline-success';
        case 10:
          return 'outline-danger';
        default:
          return 'outline-secondary';
      }
    },
    async uploadDocument(event) {
      const accept = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      const image = event.target.files[0];

      if (!image) return;
      if (!accept.includes(image.type)) {
        this.file = null;
        this.showInfoSwal('Only word or pdf Allowed', 'Warning');
        return;
      }
      if (image.size > 5 * 1024 * 1024) {
        this.file = null;
        this.showWarningSwal('The file size must not exceed 5MB');
        return;
      }

      this.fileName = image.name;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      // eslint-disable-next-line no-return-assign
      reader.onload = () => (this.file = reader.result);
    },

    async getApplicants(ctx) {
      try {
        const obj = {
          name_text: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sort_by: ctx.sortBy,
          sort: ctx.sortDesc === true ? 'DESC' : 'ASC',
          id_recruitment: this.recruitment.id,
          tab: this.$route.meta.tab,
          status: this.filter[2].model,
          from: this.filter[0].model,
          to: this.filter[1].model,
        };

        const res = await Service.getRecruitmentApplicants(obj);
        if (res.status === 200) {
          this.startPage = res.data.from;
          this.paginate.currentPage = res.data.current_page;
          this.paginate.perPage = res.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = res.data.last_page;
          this.totalRows = res.data.total;
          this.toPage = res.data.to;
          return res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },

    refreshTable(status) {
      this.$refs.refTable.refresh();
      if (status === 9) {
        this.count_started_working += 1;
      } else if (status === 7 || status === 8) {
        this.$emit('refreshTableRecruitmentProcess');
      }
    },

    async registerNewApplicant() {
      const validation = await this.$refs.observer.validate();
      if (!validation) return;
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;

      try {
        this.addPreloader();
        const obj = {
          idUser: this.currentUser.user_id,
          file: this.file,
          file_name: this.fileName,
          first_name: this.firstName,
          last_name: this.lastName,
          id_recruitment: this.recruitment.id,
          phone: this.phone,
          email: this.email,
        };

        const res = await Service.registerNewApplicant(obj);
        if (res.status === 200) {
          this.resetValues();
          this.$refs.observer.reset();
          this.refreshTable();
          this.showSuccessSwal('Success');
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    closeModalRecruimentApplicants() {
      this.$emit('closeModalRecruimentApplicants');
    },
    closeModalFilesApplicant() {
      this.showModalFilesApplicant = false;
    },
    closeModalStatusApplicant() {
      this.showModalStatusApplicant = false;
    },
    openModalStatus(applicant) {
      this.showModalStatusApplicant = true;
      this.dataApplicant = applicant;
    },
    openModalFiles(applicant) {
      this.dataApplicant = applicant;
      this.recruitmenTapplicantId = applicant.id;
      this.showModalFilesApplicant = true;
    },
    async completedRecruitment() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const obj = {
          id: this.recruitment.id,
          min_amount: this.recruitment.min_amount,
          max_amount: this.recruitment.max_amount,
          status: 6,
          user_id: this.currentUser.user_id,
        };
        const data = await Service.approveRequestRecruitment(obj);
        if (data.status === 200) {
          this.showSuccessSwal('Success', 'Recruitment process closed');
          this.closeModalRecruimentApplicants();
          this.$emit('refreshTable');
        }
        this.removePreloader();
      } catch (error) {
        console.log(error);
      }
    },
    async getStatus() {
      try {
        const response = await Service.getStatusApplicant();
        this.filter[2].options = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.timeline-individual {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  overflow: auto;
  min-height: 100em;
    background: white;
    height: 100vh;
}.backdrop{
  max-height: 20em!important;
}
</style>
