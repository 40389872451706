<template>
  <b-modal
    ref="modal-files-applicant"
    size="md"
    title="Applicant files"
    :no-close-on-backdrop="true"
    :hide-footer="true"
    @hide="closeModalFilesApplicant"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <tabler-icon
          icon="FileIcon"
          size="20"
          class="mr-1"
        />
        <h4 class="m-0 p-0 text-white">
          Applicant's files "{{ applicant.name }}"
        </h4>
      </div>
    </template>
    <table
      v-if="files.length>0"
      class="table table-striped"
    >
      <thead>
        <tr>
          <th>Phase</th>
          <th>Files</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in files"
          :key="index"
        >
          <td>{{ item.name }}</td>
          <td>
            <ul class="list-unstyled">
              <li
                v-for="(filesItem, filesIndex) in item.files.filter((item)=>item.link!=null)"
                :key="filesIndex"
              >
                <b-button
                  class="my-1"
                  variant="outline-primary"
                  @click="contentClicked(filesItem.link)"
                ><feather-icon
                   icon="FileTextIcon"
                   size="20"
                 />
                  <br><br>
                  <strong>{{ filesItem.name }}</strong>
                </b-button>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else
      class="d-flex justify-content-center align-items-center"
    >
      <p>No files were found.</p>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import Service from '@/views/commons/components/recruitment-process/services/recruiment.service';

export default {
  name: 'ModalFilesApplicant',
  props: {
    recruitmenTapplicantId: {
      type: Number,
      default: null,
    },
    applicant: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.toggleModal('modal-files-applicant');
    this.getApplicantFiles();
  },
  methods: {
    closeModalFilesApplicant() {
      this.$emit('closeModalFilesApplicant');
    },

    async getApplicantFiles() {
      try {
        this.addPreloader();
        const obj = {
          recruitment_applicant_id: this.recruitmenTapplicantId,
        };
        const res = await Service.getApplicantFiles(obj);
        if (res.status === 200) {
          this.files = res.data;
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },

    async contentClicked(url) {
      window.open(
        url,
        '_blank',
      );
    },
  },
};
</script>
