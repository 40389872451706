export default [

  {
    key: 'name',
    label: 'name',
    visible: true,
  },

  {
    key: 'email',
    label: 'email',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'salary',
    label: 'salary',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'phone',
    label: 'Phone',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'created_at',
    label: 'Registered',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'files',
    label: 'files',
    visible: true,
    // tdClass: 'text-center',
    // thClass: 'text-center',
  },

  {
    key: 'status',
    label: 'Status',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },
  {
    key: 'actions',
    label: 'Timeline',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },
  {
    key: 'more',
    label: 'More Information',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },
  {
    key: 'tracking',
    label: 'Tracking',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },
];
