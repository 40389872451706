<template>
  <b-modal
    ref="modal_view_legends"
    size="md"
    title="State legends"
    header-bg-variant="info"
    header-text-variant="dark"
    body-bg-variant="ligth"
    body-text-variant="dark"
    footer-bg-variant="ligth"
    footer-text-variant="dark"
    :no-close-on-backdrop="false"
    class="border-0 border-white"
    :keyboard="true"
    scrollable
    :hide-footer="true"
    @hidden="closeModal"
  >
    <template #modal-title>
      STATUS LEGEND
    </template>
    <template #default>
      <b-list-group
        v-if="!loading"
        variant="primary"
      >
        <b-list-group-item
          v-for="item in legendArray"
          :key="item.id"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              <b-badge
                pill
                :style="{ background: getStateColor(item.id),
                          color: getTextColor(getStateColor(item.id)),
                }"
              >
                {{ item.name.toUpperCase() }}
              </b-badge>
            </h5>
          </div>

          <p class="mb-1">
            {{ item.description }}
          </p>
        </b-list-group-item>
      </b-list-group>
      <div
        v-else
        class="loading-spinner"
      >
        <div class="spinner" />
        <div>Loading...</div>
      </div>
    </template>
  </b-modal>
</template>
<script>

import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';

export default {
  data() {
    return {
      loading: true,
      legendArray: [],
    };
  },
  mounted() {
    this.toggleModal('modal_view_legends');
    this.getLegends();
  },
  methods: {

    getStateColor(state) {
      switch (state) {
        case 1: // DRAFT
          return this.isDarkSkin ? '#ffe88d !important' : '#FFD700 !important';
        case 2: // SENT TO HUMAN TALENT
          return this.isDarkSkin ? '#9be5e5 !important' : '#00CED1 !important';
        case 3: // DRAFT BY HT
          return this.isDarkSkin ? '#ffcd85 !important' : '#FFA500 !important';
        case 4: // SENT TO CEO
          return this.isDarkSkin ? '#ca87e9 !important' : '#9400D3 !important';
        case 5: // APPROVED
          return this.isDarkSkin ? '#9fe592 !important' : '#32CD32 !important';
        case 6: // DISAPPROVED
          return this.isDarkSkin ? '#ff9473 !important' : '#FF0000 !important';
        default:
          return '';
      }
    },

    isLightColor(color) {
      const hexColor = color.replace('#', '');
      const red = parseInt(hexColor.substr(0, 2), 16);
      const green = parseInt(hexColor.substr(2, 2), 16);
      const blue = parseInt(hexColor.substr(4, 2), 16);
      const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
      return brightness > 128;
    },

    getTextColor(background) {
      return this.isLightColor(background) ? '#000000' : '#ffffff';
    },

    closeModal() {
      this.$emit('hidden');
    },

    async getLegends() {
      const response = await RecruitmentService.getRecruitmentStatus();
      this.legendArray = response.data;
      this.loading = false;
    },

  },
};
</script>

    <style scoped>
    .loading-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .spinner {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      animation: spin 2s linear infinite;
      margin-bottom: 10px;
    }

    .padspace{
      padding: 20px;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    </style>
