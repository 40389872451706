<template>
  <b-modal
    ref="stage-files"
    size="md"
    :title="`Files from ${stageName} from applicant ${applicantName}`"
    :no-close-on-backdrop="true"
    :hide-footer="true"
    @hide="closeModalFilesApplicant"
  >
    <div class="files-container">
      <div
        v-for="(item, index) in files"
        :key="`file-${index}`"
        class="file-item"
      >
        <div class="file-element">
          <img
            :src="getFileImage(item.name)"
            alt="file icon"
          >

          <span>{{
            item.name?item.name.replace("." + getFileExtension(item.name), ""):''
          }}</span>
          <a
            :id="`file-url-${index}`"
            :href="item.link"
            target="_blank"
            class="icon-button"
          >
            <img

              :src="iconsUrl.download"
              alt="url icon"
            >
          </a>
          <b-tooltip
            :target="`file-url-${index}`"
            :title="'Download ' + item.name "
            triggers="hover"
            placement="right"
          />

        </div>
      </div>
      <div
        v-if="files.length === 0"
        class="empty-files"
      >
        <section class="page_404">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="col-sm-12 col-sm-offset-1 text-center">
                  <div class="four_zero_four_bg" />

                  <div class="contant_box_404">
                    <h3>Look like you're lost</h3>

                    <h5>not files here</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div></b-modal>
</template>
<script>
export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    stageName: {
      type: String,
      default: '',
    },
    applicantName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconsUrl: {
        ai: `${process.env.BASE_URL}assets/images/icon-files/ai.png`,
        avi: `${process.env.BASE_URL}assets/images/icon-files/avi.png`,
        bmp: `${process.env.BASE_URL}assets/images/icon-files/bmp.png`,
        cdr: `${process.env.BASE_URL}assets/images/icon-files/cdr.png`,
        doc: `${process.env.BASE_URL}assets/images/icon-files/doc.png`,
        docx: `${process.env.BASE_URL}assets/images/icon-files/doc.png`,
        eps: `${process.env.BASE_URL}assets/images/icon-files/eps.png`,
        gif: `${process.env.BASE_URL}assets/images/icon-files/gif.png`,
        jpg: `${process.env.BASE_URL}assets/images/icon-files/jpg.png`,
        jpeg: `${process.env.BASE_URL}assets/images/icon-files/jpg.png`,
        mov: `${process.env.BASE_URL}assets/images/icon-files/mov.png`,
        mp3: `${process.env.BASE_URL}assets/images/icon-files/mp3.png`,
        pdf: `${process.env.BASE_URL}assets/images/icon-files/pdf.png`,
        png: `${process.env.BASE_URL}assets/images/icon-files/png.png`,
        ppt: `${process.env.BASE_URL}assets/images/icon-files/ppt.png`,
        pptx: `${process.env.BASE_URL}assets/images/icon-files/ppt.png`,
        psd: `${process.env.BASE_URL}assets/images/icon-files/psd.png`,
        sql: `${process.env.BASE_URL}assets/images/icon-files/sql.png`,
        svg: `${process.env.BASE_URL}assets/images/icon-files/svg.png`,
        txt: `${process.env.BASE_URL}assets/images/icon-files/txt.png`,
        xls: `${process.env.BASE_URL}assets/images/icon-files/xls.png`,
        xlsx: `${process.env.BASE_URL}assets/images/icon-files/xls.png`,
        xml: `${process.env.BASE_URL}assets/images/icon-files/xml.png`,
        zip: `${process.env.BASE_URL}assets/images/icon-files/zip.png`,
        other: `${process.env.BASE_URL}assets/images/icon-files/file.png`,
        url: `${process.env.BASE_URL}assets/images/icons/url.svg`,
        download: `${process.env.BASE_URL}assets/images/icons/download.svg`,
      },
    };
  },
  mounted() {
    this.toggleModal('stage-files');
  },
  methods: {
    closeModalFilesApplicant() {
      this.$emit('closeModalFilesApplicant');
    },
    getFileExtension(filename) {
      if (!filename) return '';
      const extension = filename.split('.').pop();
      return extension;
    },
    getFileImage(filename) {
      const extension = this.getFileExtension(filename);
      return this.iconsUrl[extension] || this.iconsUrl.other;
    },
  },
};
</script>
<style scoped>
/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
  width: 100%;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/722246/screenshots/3066818/404-page.gif);
  height: 650px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
.files-container {
  height: 100%;
  width: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: auto;
  padding: 10px;
  background-color: #f7f9f9;
  border: 1px solid #e5e7e9;
}
.empty-files {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
h2,
h3 {
  font-family: "Courier new", serif;
}
.file-item {
  margin-bottom: 1em;
  box-shadow: 10px 10px 5px 0px rgba(184, 177, 177, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(184, 177, 177, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(184, 177, 177, 0.75);
  width: 95%;
  border-radius: 1em;
}
.file-item:first-child {
  margin-top: 1em;
}
.file-item:last-child {
  margin-bottom: 0;
}
.file-element {
  background-color: #fff;
  width: auto;
  height: 100px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  align-items: center;
  justify-items: center;
  padding: 10px;
  position: relative;
}
.file-element img:first-child {
  margin-left: 0;
}
img {
  width: 30%;
  height: 80%;
  border-radius: 10px;
  margin: auto;
}

.file-element span {
  font-size: 1.2rem;
  font-weight: 500;
  margin: auto;
}
.icon-button {
  width: 20%!important;
  height: 30% !important ;
  border-radius: 10px;
  margin: auto!important;
  cursor: pointer;
}a{
  display: flex;
  justify-content: center;
  height: 30%;
}a img{

    height: 20px;
    width: 30px

}
</style>
