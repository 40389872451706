<template>
  <div
    :id="`wrapper-${index}`"
    class="eye-open"
  >

    <div
      id="icon-eye"
      class="icon-eyes"
      @mouseenter="toggleClass()"
      @mouseleave="toggleClass()"
    >
      <svg
        viewBox="-20 -200 320 400"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >

        <g
          id="eye"
          stroke-width="25"
          fill="none"
        >
          <g
            id="eye-lashes"
            stroke="currentColor"
          >
            <line
              x1="140"
              x2="140"
              y1="90"
              y2="180"
            />
            <line
              x1="70"
              x2="10"
              y1="60"
              y2="140"
            />
            <line
              x1="210"
              x2="270"
              y1="60"
              y2="140"
            />
          </g>

          <path
            id="eye-bottom"
            d="m0,0q140,190 280,0"
            stroke="currentColor"
          />
          <path
            id="eye-top"
            d="m0,0q140,190 280,0"
            stroke="currentColor"
          />

          <circle
            id="eye-pupil"
            cx="140"
            cy="0"
            r="40"
            fill="currentColor"
            stroke="none"
          />
        </g>
      </svg>
    </div>
  </div>

<!-- https://uimovement.com/ui/5213/show-password/ -->
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#645EAD',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    const iconEye = document.getElementsByClassName('icon-eyes');
    iconEye.forEach(element => {
      element.style.color = this.color;
    });
  },
  methods: {
    toggleClass() {
      const wrapper = document.getElementById(`wrapper-${this.index}`);
      wrapper.classList.toggle('eye-open');

    },
  },
};
</script>
<style scoped>
/* #645EAD; */

#icon-eye {
  width: 20px;
  margin: auto;
  padding: 0;
  overflow: auto;
  /* set the icon color */
  color: black;
}

/* for other color settings, use the svg's ids */
/*
#eye-pupil { color: blue; }
#eye-lashes { color: red; }
#eye-bottom, #eye-top { color: yellow; }
*/

.eye-open button::after { content: "Close"; }

.eye-close button::after { content: "Open"; }
#eye {
          /* set these to blink faster/slower */
          --duration-blink: .2s;
          --duration-lashes: .2s;

          /* calculated and fixed vars */
          --delay-lashes: var(--duration-blink);
          --duration-pupil: .1s;
          --delay-pupil: calc(var(--duration-blink)*2/3);
          }

          #eye-bottom, #eye-top { stroke-linecap: round; }

          #eye-top, #eye-lashes { transition: var(--duration-blink) ease-in; }

          #eye-pupil {
          opacity: 0;
          transition: opacity var(--duration-pupil) var(--delay-pupil) ease;
          }

          /* open state */
          .eye-open #eye-top,
          .eye-open #eye-lashes {
          transform: rotateX(.5turn);
          animation: scaleUp var(--duration-lashes) var(--delay-lashes) ease-in-out;
          }

          .eye-open #eye-pupil { opacity: 1; }

          /* close state */
          .eye-close #eye-lashes { animation: scaleDown var(--duration-lashes) var(--duration-blink) ease-in-out; }

          .eye-close #eye-pupil { opacity: 0; }

          /* keyfranes */
          @keyframes scaleUp {
          50% { transform: rotateX(.5turn) scaleY(1.15); }
          to { transform: rotateX(.5turn) scaleY(1); }
          }

          @keyframes scaleDown {
          50% { transform: scaleY(1.15); }
          to { transform: scaleY(1); }
          }
</style>
