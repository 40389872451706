<template>
  <div>
    <b-modal
      ref="modal_recruitment_process"
      v-model="onControl"
      size="xl"
      :title="'Add more information for ' + applicantData.first_name + ' ' + applicantData.last_name"
      modal-class="modal-primary"
      no-close-on-backdrop
      @hidden="close"
    >
      <validation-observer ref="observer">
        <b-row class="my-1">
          <b-col
            cols="12"
            md="6"
            xl="4"
          >

            <validation-provider
              v-slot="{ errors }"
              rules="required|max:255"
              name="firstName"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  First name:
                </template>
                <b-form-input
                  v-model="applicantDataTemporal.first_name"
                  placeholder="First name"
                  :state="errors.length > 0 ? false : null"
                />
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:255"
              name="LastName"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  Last Name:
                </template>
                <b-form-input
                  v-model="applicantDataTemporal.last_name"
                  placeholder="Last name"
                  :state="errors.length > 0 ? false : null"
                />
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="max:20"
              name="file"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="PhoneIcon"
                    class="mr-75"
                  />
                  Phone:
                </template>
                <b-form-input
                  v-model="applicantDataTemporal.phone"
                  v-mask="'#########'"
                  placeholder="Phone"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                /><small
                  v-if="errors.length > 0"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="email"
              name="file"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="MailIcon"
                    class="mr-75"
                  />
                  Email:
                </template>
                <b-form-input
                  v-model="applicantDataTemporal.email"
                  placeholder="Email"
                  type="email"
                  :state="errors.length > 0 ? false : null"
                /><small
                  v-if="errors.length > 0"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small></b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
            xl="4"
          >

            <validation-provider
              v-slot="{ errors }"
              rules="required|max:255"
              name="salary_expectation"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  Salary Expectation
                </template>
                <money
                  id="minimumAmount"
                  v-model="applicantDataTemporal.salary_expectation"
                  v-bind="vMoney"
                  class="form-control input-form"
                  :class="{ 'border-danger': vmoneyValidate }"
                />

                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="level_of_english"
              rules="required"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  Level of English
                </template>
                <v-select
                  id="select-languages"
                  v-model="applicantDataTemporal.level_of_english"
                  placeholder="Please select languages"
                  label="text"
                  :options="optionsLanguages"
                  value-field="id"
                  :reduce="(option) => option.id"
                  width="100%"
                  :style="errors[0] ? 'border: 1px solid red;':''"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Select an option
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="my-1">
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{errors}"
              name="address"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="MapPinIcon"
                    class="mr-75"
                  />
                  Address:
                </template>
                <vue-google-autocomplete
                  id="address"
                  ref="refAddress"
                  v-model="applicantDataTemporal.address"
                  class="form-control input-form"
                  placeholder="Please type your mailing address"
                  country="PE"
                  style="height: 30px !important"
                  :style="errors[0] ? 'border:1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  :state="errors[0] ? false : null"
                  @placechanged="getAddressEmployees"
                />

              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{errors}"
              name="city"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="MapPinIcon"
                    class="mr-75"
                  />
                  City:
                </template>

                <b-form-input
                  v-model="applicantDataTemporal.city"
                  placeholder="City"
                  :class="errors[0]?'border-danger':''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <b-form-group
              label-cols-lg="4"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                Zip code:
              </template>

              <b-form-input
                v-model="applicantDataTemporal.zip_code"
                placeholder="Zip code"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{errors}"
              name="city"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="MapPinIcon"
                    class="mr-75"
                  />
                  State:
                </template>

                <b-form-input
                  v-model="applicantDataTemporal.state"
                  placeholder="State"
                  :class="errors[0]?'border-danger rounded':''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <b-form-group
              label-cols-lg="4"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                Country
              </template>

              <b-form-input
                readonly
                :value="'PE'"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider>

              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-75"
                  />
                  Age:
                </template>

                <b-form-input
                  v-model="applicantDataTemporal.age"

                  placeholder="Age"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="my-1">
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="level"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="BriefcaseIcon"
                    class="mr-75"
                  />
                  Level :
                </template>

                <v-select
                  v-model="applicantDataTemporal.level"
                  :class="!isDarkSkin ? 'bg-white' : ''"
                  :clearable="false"
                  :options="optionsLevel"
                  :reduce="(option) => option.value"
                  label="text"
                  placeholder="--Select option--"
                  :disabled="!applicantDataTemporal"
                  :style="errors[0] ? 'border: 1px solid red;border-radius: 5px;' : ''"
                />
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="level"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="BriefcaseIcon"
                    class="mr-75"
                  />
                  <span>University / Institute :</span>
                </template>

                <b-form-input
                  v-model="applicantDataTemporal.institute_university"
                  placeholder="University / Institute"
                  :class="errors[0] ? 'border-danger' : ''"
                />
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="level"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon
                    icon="BriefcaseIcon"
                    class="mr-75"
                  />
                  Career:
                </template>

                <b-form-input
                  v-model="applicantDataTemporal.career"
                  placeholder="Career"
                  :class="errors[0] ? 'border-danger' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

      </validation-observer>
      <b-row>
        <b-container
          fluid
        >
          <b-row>
            <b-col
              cols="12"
              xl="4"
            >
              <b-card>
                <validation-observer ref="formExperience">
                  <b-card-body>
                    <validation-provider
                      v-slot="{errors}"
                      rules="required"
                      name="dateFrom"
                    >
                      <b-form-group
                        label-cols-lg="5"
                        label-cols-md="5"
                        label-cols-sm="4"
                        label-cols="12"
                        content-cols-lg="7"
                        content-cols-md="7"
                        content-cols-sm="8"
                        content-cols="12"
                      >
                        <template #label>
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          Date From :
                        </template>
                        <kendo-datepicker
                          id="date"
                          v-model="formNewExperiencie.dateFrom"
                          v-mask="'##/##/####'"
                          :first-day-of-week="1"
                          placeholder="MM/DD/YYYY"
                          :format="'MM/dd/yyyy'"
                          :state="errors[0] ? false : null"
                          :class="errors[0] ? 'border-danger' : ''"
                          :max="new Date()"
                          class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                        />
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      v-slot="{errors}"
                      rules="required"
                      name="dateEnd"
                    >
                      <b-form-group
                        label-cols-lg="5"
                        label-cols-md="5"
                        label-cols-sm="4"
                        label-cols="12"
                        content-cols-lg="7"
                        content-cols-md="7"
                        content-cols-sm="8"
                        content-cols="12"
                      >
                        <template #label>
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          Date End :
                        </template>
                        <kendo-datepicker
                          id="date"
                          v-model="formNewExperiencie.dateEnd"
                          v-mask="'##/##/####'"
                          :first-day-of-week="1"
                          placeholder="MM/DD/YYYY"
                          :format="'MM/dd/yyyy'"
                          :state="errors[0] ? false : null"
                          :class="errors[0] ? 'border-danger' : ''"
                          :max="new Date()"
                          class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                        />
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      v-slot="{errors}"
                      rules="required"
                      name="Role"
                    >
                      <b-form-group
                        label-cols-lg="5"
                        label-cols-md="5"
                        label-cols-sm="4"
                        label-cols="12"
                        content-cols-lg="7"
                        content-cols-md="7"
                        content-cols-sm="8"
                        content-cols="12"
                      >
                        <template #label>
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          Role:
                        </template>
                        <b-form-input
                          v-model="formNewExperiencie.role"
                          placeholder="Role"
                          :state="errors[0]?false:null"
                        />
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      v-slot="{errors}"
                      rules="required"
                      name="Company"
                    >
                      <b-form-group
                        label-cols-lg="5"
                        label-cols-md="5"
                        label-cols-sm="4"
                        label-cols="12"
                        content-cols-lg="7"
                        content-cols-md="7"
                        content-cols-sm="8"
                        content-cols="12"
                      >
                        <template #label>
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          Company :
                        </template>
                        <b-form-input
                          v-model="formNewExperiencie.company"
                          placeholder="Company"
                          :state="errors[0]?false:null"
                        />
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      v-slot="{errors}"
                      rules="required"
                      name="reference-number"
                    >
                      <b-form-group
                        label-cols-lg="5"
                        label-cols-md="5"
                        label-cols-sm="4"
                        label-cols="12"
                        content-cols-lg="7"
                        content-cols-md="7"
                        content-cols-sm="8"
                        content-cols="12"
                      >
                        <template #label>
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          Reference number :
                        </template>
                        <b-form-input
                          v-model="formNewExperiencie.referenceNumber"
                          placeholder="Reference number"
                          :state="errors[0]?false:null"
                        />
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      v-slot="{errors}"
                      name="email"
                      rules="email|required"
                    >
                      <b-form-group
                        label-cols-lg="5"
                        label-cols-md="5"
                        label-cols-sm="4"
                        label-cols="12"
                        content-cols-lg="7"
                        content-cols-md="7"
                        content-cols-sm="8"
                        content-cols="12"
                      >
                        <template #label>
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          Email :
                        </template>
                        <b-form-input
                          v-model="formNewExperiencie.email"
                          placeholder="Email"
                          :state="errors[0]?false:null"
                        />
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      v-slot="{errors}"
                      rules="required"
                      name="mainFunctions"
                    >
                      <b-form-group>
                        <template #label>
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          Main functions:
                        </template>
                        <b-form-textarea
                          id="functions"
                          v-model="formNewExperiencie.function"
                          class="text-area-box roboto-class"
                          name="text"
                          cols="30"
                          rows="10"
                          max-length="1000"
                          placeholder="Describe your functions here ... "
                          :state="errors[0]?false:null"
                        />
                        <span class="float-right">{{ formNewExperiencie.function ? formNewExperiencie.function.length: 0 }} / 1000</span>
                      </b-form-group>
                    </validation-provider>
                    <b-button
                      variant="success"
                      class="w-100"
                      @click="addExperience"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                      />
                      Add
                    </b-button>
                  </b-card-body>
                </validation-observer>
              </b-card>
            </b-col>
            <b-col
              v-if="!workExperiencie.length"
              cols="12"
              xl="8"
            >
              <div
                class="d-flex justify-content-center align-items-center h-100 text-info rounded"
                :class="isDarkSkin ? 'border-light':''"
                style="font-size: 15px; letter-spacing: 1px; font-weight: bolder; min-height: 300px;"
                :style="!isDarkSkin ? 'background-color: #F4F6F6;':''"
              >
                No work experience
              </div>
            </b-col>
            <b-col
              v-else
              cols="12"
              xl="8"
            >
              <b-row
                class="mx-2 scroll"
                style="max-height: 550px;overflow-y: auto;"
              >
                <b-col
                  v-for="(item , index) in workExperiencie"
                  :key="index"
                  cols="12"
                  md="6"
                  class="p-1"
                >
                  <div
                    class="w-100 py-1 px-2 rounded text-dark my-card position-relative"
                  >
                    <feather-icon
                      v-b-tooltip.hover.v-danger
                      v-b-tooltip.hover.bottom
                      title="Delete"
                      icon="Trash2Icon"
                      size="30"
                      class="cursor-pointer rounded-circle my-icon"
                      style="position: absolute; top: 15px;right: 15px;padding: 7px;"
                      @click="deleteItem(index)"
                    />
                    <span
                      class="w-100 d-flex my-1"
                      style="font-size: 13px;"
                      :style="isDarkSkin ? 'color: black;':''"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      <b class="mr-1 text-uppercase">
                        Date From : </b>
                      {{ item.dateFrom | myGlobal }}
                    </span>
                    <span
                      class="w-100 d-flex my-1"
                      style="font-size: 13px;"
                      :style="isDarkSkin ? 'color: black;':''"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      <b class="mr-1 text-uppercase">
                        Date End : </b>{{ item.dateEnd | myGlobal }}
                    </span>
                    <span
                      class="w-100 d-flex my-1"
                      style="font-size: 13px;"
                      :style="isDarkSkin ? 'color: black;':''"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      <b class="mr-1 text-uppercase">Role : </b>
                      {{ item.role }}
                    </span>
                    <span
                      class="w-100 d-flex my-1"
                      style="font-size: 13px;"
                      :style="isDarkSkin ? 'color: black;':''"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      <b class="mr-1 text-uppercase">Company : </b>
                      {{ item.company }}
                    </span>
                    <span
                      class="w-100 d-flex my-1"
                      style="font-size: 13px;"
                      :style="isDarkSkin ? 'color: black;':''"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      <b class="mr-1 text-uppercase">Reference Number : </b>
                      {{ item.referenceNumber }}
                    </span>
                    <span
                      class="w-100 d-flex my-1"
                      style="font-size: 13px;"
                      :style="isDarkSkin ? 'color: black;':''"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      <b class="mr-1 text-uppercase">Email : </b>
                      {{ item.email }}
                    </span>
                    <p
                      class="w-100 d-flex flex-column p-0"
                      style="font-size: 13px;"
                      :style="isDarkSkin ? 'color: black;':''"
                    >
                      <b class="text-uppercase">
                        <feather-icon
                          icon="CalendarIcon"
                          class="mr-75"
                        />
                        My functions were :</b>
                      <span
                        class="px-2 py-1"
                        :style="isDarkSkin ? 'color: black !important;':''"
                      >
                        {{ item.function.length > limit && indexDescription===index ? item.function : item.function.substring(0,limit) }}
                        <template v-if="item.function.length > limit">
                          <b-button
                            v-if="indexDescription!==index"
                            size="sm"
                            variant="link"
                            class="p-0 font-weight-bolder border-bottom-primary rounded-0"
                            @click="indexDescription = index"
                          >
                            ... Show more
                          </b-button>
                          <b-button
                            v-else
                            size="sm"
                            variant="link"
                            class="p-0 font-weight-bolder border-bottom-primary rounded-0"
                            @click="indexDescription = null"
                          >
                            Hidden
                          </b-button>
                        </template>
                      </span>
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </b-container>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-around align-items-center">
          <b-button
            variant="primary"
            @click="save()"
          >
            Save
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { VMoney } from 'v-money';
import { mapGetters } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import moment from 'moment/moment';
import ProfileService from '@/service/profile/profile.service';

export default {
  components: { VueGoogleAutocomplete },
  directives: { money: VMoney },
  props: {
    applicantData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLanguages: [
        { id: 1, text: 'A1' },
        { id: 2, text: 'A2' },
        { id: 3, text: 'B1' },
        { id: 4, text: 'B2' },
        { id: 5, text: 'C1' },
        { id: 6, text: 'C2' },
      ],
      formNewExperiencie: {
        dateFrom: null,
        dateEnd: null,
        role: null,
        function: null,
        company: null,
        referenceNumber: null,
      },
      originalWorkExperiencie: [],
      workExperiencie: [],
      loadExperiencie: false,
      editExperiencie: false,
      validateForm: true,
      indexDescription: null,
      limit: 100,
      optionsLevel: [
        {
          value: 'tecnico',
          text: 'Tecnico',
        },
        {
          value: 'universitario',
          text: 'Universitario',
        },
        {
          value: 'truncos',
          text: 'Truncos',
        },
        {
          value: 'estudiantes',
          text: 'Estudiantes',
        },

      ],
      newPassword: null,
      confirmPassword: null,
      userId: null,
      result: null,
      valid: false,
      onControl: false,
      applicantDataTemporal: {},
      vMoney: {
        decimal: '.',
        thousand: ',',
        prefix: 'S/ ',
        precision: 2,
        min: 0.0,
      },
      vmoneyValidate: false,
    };
  },
  computed: {
    ...mapGetters({
      client: 'DebtSolutionClients/G_CLIENTS',
      currentUser: 'auth/currentUser',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
  },
  async created() {
    this.applicantDataTemporal = { ...this.applicantData };
    this.formatExperience();
    this.onControl = true;
  },

  methods: {

    formatExperience() {
      if (!this.applicantDataTemporal.work_experience) {
        return;
      }
      const dataExperiencie = JSON.parse(this.applicantDataTemporal.work_experience) || [];
      this.originalWorkExperiencie = dataExperiencie.length ? dataExperiencie.map(item => ({
        dateFrom: item.dateFrom,
        dateEnd: item.dateEnd,
        role: item.role,
        function: item.function,
        company: item.company || null,
        referenceNumber: item.referenceNumber || null,
        email: item.email,
      })) : null;
      this.workExperiencie = this.applicantDataTemporal.work_experience ? dataExperiencie.map(item => ({
        dateFrom: item.dateFrom,
        dateEnd: item.dateEnd,
        role: item.role,
        function: item.function,
        company: item.company || null,
        referenceNumber: item.referenceNumber || null,
        email: item.email,
      })) : null;
    },

    async deleteItem(index) {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) {
        return;
      }
      this.workExperiencie.splice(index, 1);
    },
    async addExperience() {
      this.validateForm = true;
      const validate = await this.$refs.formExperience.validate();
      if (!validate) {
        return;
      }
      this.formNewExperiencie.dateFrom = moment(this.formNewExperiencie.dateFrom).format('YYYY-MM-DD');
      this.formNewExperiencie.dateEnd = moment(this.formNewExperiencie.dateEnd).format('YYYY-MM-DD');
      this.workExperiencie.push(this.formNewExperiencie);

      this.formNewExperiencie = {
        dateFrom: null,
        dateEnd: null,
        role: null,
        function: null,
      };
      this.validateForm = false;
    },
    getAddressEmployees(data) {
      this.$refs.refAddress.$el.value = `${data.route || ''} ${data.street_number || ''}`;
      this.applicantDataTemporal.address = `${data.route || ''} ${data.street_number || ''}`;
      this.applicantDataTemporal.city = data.administrative_area_level_1 || '';
      this.applicantDataTemporal.zip_code = data.postal_code || '';
      this.applicantDataTemporal.state = data.locality || '';
      this.applicantDataTemporal.country = 'PE';
    },
    close() {
      this.$emit('close');
    },
    async save() {
      const validation = await this.$refs.observer.validate();
      if (!validation) return;
      this.userId = this.currentUser.user_id;
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        const params = {
          applicant_id: this.applicantDataTemporal.id,
          first_name: this.applicantDataTemporal.first_name,
          last_name: this.applicantDataTemporal.last_name,
          phone: this.applicantDataTemporal.phone,
          email: this.applicantDataTemporal.email,
          address: this.applicantDataTemporal.address,
          city: this.applicantDataTemporal.city,
          zip_code: this.applicantDataTemporal.zip_code,
          state: this.applicantDataTemporal.state,
          country: 'PE',
          age: this.applicantDataTemporal.age,
          level: this.applicantDataTemporal.level,
          institute_university: this.applicantDataTemporal.institute_university,
          career: this.applicantDataTemporal.career,
          work_experience: this.workExperiencie,
          salary_expectation: this.applicantDataTemporal.salary_expectation,
          level_of_english: this.applicantDataTemporal.level_of_english,

        };

        const result = await ProfileService.updateApplicant(params);

        if (result.status === 200) {
          this.showSuccessSwal('Success', 'The applicant was updated successfully');
        }
        this.$emit('refresh');

        this.$emit('close');
      } catch (error) {
        console.error(error);
      }
    },

  },
};
</script>
<style scoped>
.scroll::-webkit-scrollbar{
  width: 12px;
  border-radius: 10px;
  background:#CACFD2;
}
.scroll::-webkit-scrollbar-thumb{
  background-image: linear-gradient(to bottom, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  border: 1px solid #c777b9;
}
.my-card {
  background-image: radial-gradient( circle farthest-corner at 5.3% 17.2%,  rgb(208, 252, 255) 0%, rgb(134, 162, 238) 90% );
}
.my-card:hover{
  transition: 0.4s ease-in-out;
  background-image: radial-gradient( circle farthest-corner at 5.3% 17.2%,  rgb(112, 169, 243) 0%, rgb(201, 243, 245) 90% );
}
.my-icon{
  transition: 0.3s ease-in-out;
  color: red;
  text-shadow: 0px 1px 20px red;
  background-color: white;
}
.my-card:hover .my-icon{
  transform: scale(1);
  color: white;
  background-color: red;
}
</style>
