<template>
  <b-modal
    ref="modal-status-applicant"
    size="md"
    title="Change Status Applicant"
    :no-close-on-backdrop="true"
    :hide-footer="(isTabRrhh && [8,9,10].includes(applicant.id_status)||(isTabChief && [7,8,9,10].includes(applicant.id_status)))?true:false"
    :keyboard="true"
    @hide="closeModalStatusApplicant"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <tabler-icon
          icon="UserPlusIcon"
          size="20"
          class="mr-1"
        />
        <h4 class="m-0 p-0 text-white text-uppercase">
          Applicant's status "{{ applicant.name }}"
        </h4>
      </div>
    </template>
    <b-container
      class="p-1"
      fluid
    >
      <div class="d-flex flex-row-reverse">
        <b-button
          variant="primary"
          class="mb-1"
          size="sm"
          @click="openModalInfoStatus"
        >
          Status of applicants
          <tabler-icon
            icon="InfoCircleIcon"
            size="15"
          />
        </b-button>
      </div>
      <b-card
        title="Applicant's current process"
        :style="isDarkSkin ? 'background: #2d3238' : ''"
        class="shadow-none"
      >
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div
                class="rounded text-info border-info font-medium-1 text-center d-block d-xl-flex"
              >
                <strong class="p-1 bg-info text-white w-100 d-flex justify-content-center align-items-center">{{ currentStatus.name }} </strong>
                <div class="text-warning p-1 w-100 d-block">
                  <div class="d-flex w-100 d-flex justify-content-center align-items-center text-center">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-1"
                    />
                    {{ currentStatus.date_time_meeting | myGlobalDay }}
                    <template v-if="currentStatus.status_meeting_id !== 3 && isTabRrhh">
                      <feather-icon
                        v-if="currentStatus.date_time_meeting"
                        v-b-tooltip.hover.v-primary
                        v-b-tooltip.hover.bottom
                        icon="RefreshCwIcon"
                        size="18"
                        class="text-primary hover-me ml-1 cursor-pointer"
                        title="REPROGRAM MEETING"
                        @click="addMeeting(2)"
                      />
                      <feather-icon
                        v-else
                        v-b-tooltip.hover.v-success
                        v-b-tooltip.hover.bottom
                        icon="PlusSquareIcon"
                        size="18"
                        class="text-success hover-me ml-1 cursor-pointer"
                        title="ADD MEETING"
                        @click="addMeeting(1)"
                      />
                    </template>
                  </div>
                  <strong
                    v-if="currentStatus.date_time_meeting"
                    class="d-flex justify-content-center align-items-center text-center w-100 font-weight-bolder"
                    :class="bgMeeting[currentStatus.status_meeting_id - 1]"
                  >
                    {{ `${currentStatus.status_meeting} meeting` }}
                  </strong>
                </div>
              </div>
            </b-col>
            <b-card
              v-if="showMeeting"
              cols="12"
              class="w-100 p-1 ml-1 mr-1 mt-1 list-group-item-info"
              :class="!isDarkSkin? 'border-info':''"
            >
              <validation-observer
                ref="formMeeting"
                class="w-100"
              >
                <b-row>
                  <b-col
                    cols="12"
                    xl="6"
                  >
                    <validation-provider
                      v-slot="{errors}"
                      name="date-meeting"
                      rules="required"
                    >
                      <b-form-group label="Date Meeting">
                        <kendo-datepicker
                          id="date_start"
                          v-model="meeting.date"
                          v-mask="'##/##/####'"
                          placeholder="MM/DD/YYYY"
                          :format="'MM/dd/yyyy'"
                          class="w-100 rounded bg-white k-picker-custom picker-select-date"
                          :show-week-number="false"
                          :min="new Date()"
                          :state="errors[0]?false:null"
                          :class="errors[0]?'border-danger rounded':''"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="6"
                  >
                    <validation-observer
                      v-slot="{errors}"
                      name="time-meeting"
                      rules="required"
                    >
                      <b-form-group label="Time Meeting">
                        <kendo-timepicker
                          v-model="meeting.time"
                          v-mask="'##:##'"
                          :format="'HH:mm'"
                          :interval="15"
                          class="w-100 rounded bg-white"
                          placeholder="00:00"
                          style="height: 2.73rem"
                          :state="errors[0]?false:null"
                          :class="errors[0]?'border-danger rounded':''"
                        />
                      </b-form-group>
                    </validation-observer>
                  </b-col>
                </b-row>
              </validation-observer>
              <b-row class="mt-1">
                <b-col
                  cols="12"
                >
                  <b-button
                    variant="primary"
                    class="float-right"
                    size="sm"
                    @click="reprogramMeeting"
                  >
                    <feather-icon
                      icon="SaveIcon"
                    />
                    Program meeting
                  </b-button>
                  <b-button
                    variant="danger"
                    class="mr-1 float-right"
                    size="sm"
                    @click="showMeeting = false"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-col
              v-if="isTabRrhh && ![7,8,9,10].includes(applicant.id_status) "
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Upload Files"
                label-for="files"
              >
                <div>
                  <b-form-file
                    v-model="files"
                    placeholder="Upload Employee's Files"
                    accept=".jpg, .png, .docx, .pdf"
                    multiple
                    @change="uploadDocuments($event)"
                  />
                </div>
              </b-form-group>
              <!-- <div>
                <img
                  src="@/assets/images/icon-files/doc.png"
                  width="58px"
                  height="58px"
                  class="rounded-circle avatar-card"
                >
              </div> -->
            </b-col>
            <b-col
              v-if="currentStatus.comment"
              cols="12"
              class="mt-1"
            >
              <b-form-group label="Commentary">
                <b-form-textarea
                  id="comment"
                  v-model="currentStatus.comment"
                  type="text"
                  rows="3"
                  placeholder="Write Commentary or Notes"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="currentStatus.status_date"
              cols="6"
              class="mt-1"
            >
              <b-form-group label="Date of execution">
                <kendo-datepicker
                  id="date_start"
                  v-model="currentStatus.status_date"
                  v-mask="'##/##/####'"
                  placeholder="MM/DD/YYYY"
                  :format="'MM/dd/yyyy'"
                  class="w-100 rounded bg-transparent k-picker-custom picker-select-date"
                  :show-week-number="false"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <div
          v-if="![1,7,8].includes(currentStatus.id) && !currentStatus.id_psycholaboral && isTabRrhh"
          class="d-flex justify-content-center mt-1"
        >
          <b-button
            variant="secondary"
            size="sm"
            @click="openPsycholaboral=true"
          >
            <tabler-icon
              size="20"
              icon="FilePlusIcon"
            />
            Create Report Psycholaboral
          </b-button>
        </div>
        <div
          v-if="currentStatus.id_psycholaboral"
          class="d-flex justify-content-center"
        >
          <b-button
            variant="primary"
            size="sm"
            class="mt-1"
            @click="openPsycholaboral=true"
          >
            <tabler-icon
              size="20"
              icon="FileInvoiceIcon"
            />
            View Report Psycholaboral
          </b-button>
        </div>
      </b-card>
      <hr v-if="showChangeStatus">
      <b-col
        v-if="isTabRrhh"
        cols="12"
      >
        <div class="d-flex justify-content-center">
          <b-button
            v-if="!showChangeStatus&&![6,7,8,9,10].includes(currentStatus.id)"
            variant="success"
            @click="showCardChangeStatus"
          >
            Change applicant status
          </b-button>
        </div>
      </b-col>

      <b-card
        v-if="showChangeStatus"
        title="Change Status"
        :style="isDarkSkin ? 'background: #2d3238' : ''"
      >
        <button
          type="button"
          class="close"
          style="position: absolute; top: 10px; right: 10px; cursor: pointer;"
          @click="hiddenChangeStatus"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <validation-observer ref="formChangeStatus">
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-group
                  label="Select Status"
                  label-for="status"
                >
                  <v-select
                    id="status"
                    v-model="status"
                    placeholder="select a state for the applicant"
                    label="name"
                    :options="optionsStatus"
                    :reduce="(option) => option.id"
                    width="100%"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                    :style="validationStatus?'border: 1px solid red;':''"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                Create a meeting?
                <b-form-checkbox
                  id="languages-status"
                  v-model="haveMeeting"
                  :checked="haveMeeting"
                  :value="true"
                  :unchecked-value="false"
                  class="custom-control-primary pt-50"
                  name="check-button"
                  switch
                >YES</b-form-checkbox>
              </b-form-group>

            </b-col>
            <b-col
              v-if="haveMeeting"
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="date_start"
                rules="required"
              >
                <b-form-group label="Date of execution">
                  <kendo-datepicker
                    id="date_start"
                    v-model="status_date"
                    v-mask="'##/##/####'"
                    placeholder="MM/DD/YYYY"
                    :format="'MM/dd/yyyy'"
                    :state="
                      errors[0]
                        ? false
                        : null
                    "
                    :class="
                      errors[0]
                        ? 'border-danger'
                        : ''
                    "
                    :min="minDate"
                    class="w-100 rounded bg-transparent k-picker-custom picker-select-date"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="haveMeeting"
              cols="6"
            >
              <validation-provider
                v-slot="{errors}"
                rules="required"
                name="time-execution"
              >
                <b-form-group label="Time of execution">
                  <kendo-timepicker
                    v-model="status_time"
                    v-mask="'##:##'"
                    :format="'HH:mm'"
                    :interval="15"
                    class="w-100 rounded bg-transparent"
                    placeholder="00:00"
                    style="height: 2.73rem"
                    :class="errors[0]?'border-danger rounded':''"
                  />
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <b-col
                v-if="status===6"
                cols="12"
                class="d-flex justify-content-between"
                style="margin-bottom:5px"
              >
                <div class="d-flex justify-content-between align-items-center w-100">
                  <span>Salary </span>
                  <strong class="text-info">range (S/{{ recruitment.min_amount | currency }} - S/{{ recruitment.max_amount | currency }})</strong>
                </div>

              </b-col>
              <b-col
                v-if="status===6"
                cols="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="salary"
                  rules="required|validate-amount|money-required"
                >
                  <b-form-group>

                    <money
                      v-model="salary"
                      v-bind="vMoney"
                      class="form-control input-form"
                      :class="{
                        'border-success': errors.length > 0 || (salary >= recruitment.min_amount && salary <= recruitment.max_amount),
                        'border-danger': errors.length > 0 || salary < recruitment.min_amount || salary > recruitment.max_amount
                      }"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                    <small
                      v-if="salary>recruitment.max_amount || salary<recruitment.min_amount"
                      class="text-danger"
                    >
                      The salary must respect the minimum and maximum ranges
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-col>
          </b-row>
        </validation-observer>

      </b-card>
    </b-container>
    <div
      v-if="showComment"
      style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 9999; width: 300px; height: auto;"
    >
      <b-card
        :style="isDarkSkin ? 'background: #2d3239' : 'background: #eff4fd'"
        style="border: 1px solid rgba(205, 205, 205, 0.418);border-radius:5px"
      >
        <button
          type="button"
          class="close"
          style="position: absolute; top: 10px; right: 10px; cursor: pointer;"
          @click="showComment = false"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <b-form-group
          v-if="status===11"
          label="What is the reason?"
        >
          <v-select
            id="select-languages"
            v-model="motive_id"
            placeholder="Please select languages"
            label="name"
            :options="optionsMotives"
            value-field="id"
            :reduce="(option) => option.id"
            width="100%"
          />

        </b-form-group>
        <b-form-group label="Commentary">
          <b-form-textarea
            id="comment"
            v-model="comment"
            type="text"
            rows="10"
            placeholder="Write Commentary or Notes"
          />

        </b-form-group>

        <div class="text-right"> <!-- Agregamos una clase para alinear a la derecha -->
          <b-button
            variant="primary"
            @click="changeStatusApplicant"
          >
            Save
          </b-button>
        </div>
      </b-card>
    </div>
    <template #modal-footer>
      <div
        class="w-100 d-flex align-items-center"
        :class="((isTabChief && applicant.id_status === 6)||(isTabRrhh && applicant.id_status === 7))?'justify-content-between':'justify-content-end '"
      >
        <div v-if="((isTabChief && applicant.id_status === 6)||(isTabRrhh && applicant.id_status === 7))">
          <b-button
            variant="danger"
            @click="disapproveStatusApplicant()"
          >
            {{ isTabRrhh?'Rejected':'Disapprove' }}
          </b-button>
        </div>
        <div v-if="((isTabChief && applicant.id_status === 6)||(isTabRrhh && applicant.id_status === 7))">
          <b-button
            variant="primary"
            @click="approveStatusApplicant()"
          >
            {{ isTabRrhh?'Work started':'Approve' }}
          </b-button>
        </div>
        <div v-if="isTabRrhh && ![7,8,9,10].includes(applicant.id_status)">
          <b-button
            variant="primary"
            @click="showChangeStatus ? continueSaveStatus() : changeStatusApplicant()"
          >
            {{ showChangeStatus ? 'Continue' : 'Save Documents' }}
          </b-button>
        </div>

      </div>
    </template>
    <modal-info-status
      v-if="showModalInfoStatus"
      @closeModalStatusInfo="closeModalStatusInfo"
    />
    <modal-psycholaboral
      v-if="openPsycholaboral"
      :psycho-evaluation-array="psychoEvaluationArray"
      :applicant-id="applicant.id"
      :selected-aplicant-obj="selectedAplicantObj"
      :applicant-name="applicant.name"
      @hidden="openPsycholaboral = false"
      @closeModal="closeModalPyscho()"
      @refreshButton="refreshButton"
    />
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { VMoney } from 'v-money';
import Service from '@/views/commons/components/recruitment-process/services/recruiment.service';
import ModalInfoStatus from '@/views/commons/components/recruitment-process/components/modals/ModalInfoStatus.vue';
import ModalPsycholaboral from '@/views/rrhh/views/psycholaboral-evaluation-settings/CreatePsycholaboralModal.vue';
import ServicePsycholaboral from '@/views/rrhh/views/psycholaboral-evaluation-settings/psycholaboral-evaluation-settings.service';

export default {
  name: 'ModalStatusApplicant',
  directives: { money: VMoney },
  components: {
    ModalInfoStatus,
    ModalPsycholaboral,
  },
  props: {
    applicant: {
      type: Object,
      default: () => {},
    },
    recruitment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      motive_id: null,
      optionsMotives: [],
      vMoney: {
        decimal: '.',
        thousand: ',',
        prefix: 'S/ ',
        precision: 2,
        min: 0.0,
      },
      vmoneyValidate: false,
      optionsStatus: [],
      validationStatus: false,
      validateDate: null,
      status: null,
      showModalInfoStatus: false,
      files: [],
      comment: null,
      file: null,
      fileName: null,
      showChangeStatus: false,
      status_date: null,
      status_time: null,
      showComment: false,
      minDate: new Date(),
      openPsycholaboral: false,
      psychoEvaluationArray: [],
      currentStatus: {
        id: null,
        status: null,
        status_date: null,
        comment: null,
      },
      salary: null,
      selectedAplicantObj: null,
      showMeeting: false,
      meeting: {
        date: null,
        time: null,
        status: 1,
      },
      bgMeeting: [
        'text-warning',
        'text-info',
        'text-success',
      ],
      haveMeeting: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_COUNT_MEETING_RECRUITMENT: 'NotificationStore/G_COUNT_MEETING_RECRUITMENT',
    }),
    isTabChief() {
      return this.$route.meta.tab === 1;
    },
    isTabRrhh() {
      return this.$route.meta.tab === 2;
    },
    isTabCeo() {
      return this.$route.meta.tab === 3;
    },
    currentMonth() {
      return parseInt(moment().format('MM'), 10);
    },
    currentYear() {
      return parseInt(moment().format('YYYY'), 10);
    },
  },
  async mounted() {
    this.toggleModal('modal-status-applicant');
    await this.getPsychoEvaluation();
    await this.getCurrentStatus();
    await this.getStatus();
    await this.getRejectedMotives();
  },
  methods: {

    async getRejectedMotives() {
      try {
        this.addPreloader();

        const data = await Service.getRejectedMotivesApplicant();
        console.log(data)
        if (data.status == 200) {
          this.optionsMotives = data.data;
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    ...mapActions({
      A_COUNT_MEETINGS_RECRUITMENT: 'NotificationStore/A_COUNT_MEETINGS_RECRUITMENT',
    }),
    closeModalPyscho() {
      this.getSingleApplicantReport();
      this.openPsycholaboral = false;
    },
    async getSingleApplicantReport() {
      try {
        this.addPreloader();
        const obj = {
          id_psycholaboral: this.currentStatus.id_psycholaboral,
        };
        const res = await Service.getSingleApplicantReport(obj);
        if (res.status === 200) {
          this.selectedAplicantObj = res.data;
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    hiddenChangeStatus() {
      this.showChangeStatus = false;
      this.status = null;
      this.status_date = null;
      this.status_time = null;
      this.comment = null;
      this.salary = null;
    },
    refreshButton() {
      this.getCurrentStatus();
    },
    async getCurrentStatus() {
      try {
        const params = {
          applicant_id: this.applicant.id,
        };
        const res = await Service.getCurrentStatus(params);
        if (res.status === 200) {
          this.currentStatus = res.data;
          console.log(this.currentStatus, 'status');
          if (this.currentStatus.id_psycholaboral) this.getSingleApplicantReport();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPsychoEvaluation() {
      try {
        this.addPreloader();
        const res = await ServicePsycholaboral.getPsychoEvaluation();
        if (res.status === 200) {
          this.psychoEvaluationArray = res.data;
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    async uploadDocuments(event) {
      this.files = await this.asyncFileLoad(event);
    },
    async asyncFileLoad(event) {
      return new Promise(resolve => {
        const accept = [
          'image/png',
          'image/jpg',
          'image/jpeg',
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ];
        const images = event.target.files;
        const loadedFiles = [];

        for (let i = 0; i < images.length; i += 1) {
          const image = images[i];
          const fileName = image.name;

          if (!image) return;
          if (!accept.includes(image.type)) {
            this.showInfoSwal('Only image or pdf Allowed', 'Warning');
            return;
          }
          if (image.size > 5 * 1024 * 1024) {
            this.showWarningSwal('The file size must not exceed 5MB');
            return;
          }

          const reader = new FileReader();
          reader.readAsDataURL(image);

          reader.onload = () => {
            loadedFiles.push({
              name: fileName,
              data: reader.result,
            });

            if (loadedFiles.length === images.length) {
              resolve(loadedFiles);
            }
          };
        }
      });
    },
    async getStatus() {
      try {
        const response = await Service.getStatusApplicant();
        this.optionsStatus = response.data.filter(item => item.id !== 7 && item.id !== 8 && item.id !== 9 && item.id !== 10);
      } catch (error) {
        console.log(error);
      }
    },
    async continueSaveStatus() {
      this.vmoneyValidate = false;
      const result = await this.$refs.formChangeStatus.validate();
      if (this.status === 6) {
        if (this.salary > this.recruitment.max_amount || this.salary < this.recruitment.min_amount) {
          return;
        }
      }
      if (!result) {
        return;
      }
      this.showComment = true;
    },
    async changeStatusApplicant() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        const params = {
          id: this.applicant.id,
          status_id: this.status,
          commentary: this.comment,
          status_date: this.showChangeStatus && this.haveMeeting ? moment(`${this.status_date} ${this.status_time}`).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
          salary: this.salary,
          user_id: this.currentUser.user_id,
          files: this.files,
          motive_id: this.motive_id
        };
        this.addPreloader();
        const response = await Service.changeStatusApplicant(params);
        if (response.status === 200) {
          const date = moment(`${this.status_date} ${this.status_time}`).format('YYYY-MM-DD HH:mm:ss');

          if (this.status_date && this.status_time && this.haveMeeting) {
            await this.newMeeting(date, this.status);
          }
          this.removePreloader();
          this.showSuccessSwal('Success');
          if (this.isTabRrhh) {
            this.getCurrentStatus();
            this.$emit('refreshTableApplicants', this.status);
            if ([9, 10].includes(this.status)) {
              this.closeModalStatusApplicant();
            }
            this.showModalInfoStatus = false;
            this.showChangeStatus = false;
            this.showComment = false;
            this.files = null;
            this.comment = null;
            this.status = null;
            this.status_date = null;

          } else {
            this.$emit('refreshTableApplicants', this.status);
            this.closeModalStatusApplicant();
          }
          this.motive_id=null;
        }
        this.removePreloader();
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    closeModalStatusApplicant() {
      this.$emit('closeModalStatusApplicant');
    },
    openModalInfoStatus() {
      this.showModalInfoStatus = true;
    },
    closeModalStatusInfo() {
      this.showModalInfoStatus = false;
      this.getStatus();
    },
    showCardChangeStatus() {
      this.showChangeStatus = true;
    },
    approveStatusApplicant() {
      if (this.isTabChief) {
        this.status = 7;
      } else {
        this.status = 9;
      }
      this.changeStatusApplicant();
    },
    disapproveStatusApplicant() {
      if (this.isTabChief) {
        this.status = 8;
      } else {
        this.status = 10;
      }
      this.continueSaveStatus();
      this.showComment = true;
    },
    addMeeting(status = 1) {
      this.meeting.date = null;
      this.meeting.time = null;
      this.meeting.status = status; // status 1: pending, 2: reprogrammed, 3: completed
      console.log(status);
      this.showMeeting = !this.showMeeting;
    },
    async reprogramMeeting() {
      try {
        const validate = await this.$refs.formMeeting.validate();
        if (!validate) return;

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();

        const date = moment(`${this.meeting.date} ${this.meeting.time}`).format('YYYY-MM-DD HH:mm:ss');
        const meeting = await this.newMeeting(date, this.currentStatus.id);
        if (meeting === 200) this.showSuccessSwal();
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.log('error', error);
      }
    },
    async newMeeting(dateTime, aplicantStatus) {
      const params = {
        applicant_id: this.applicant.id,
        status_applicant_id: aplicantStatus,
        date_time: dateTime,
        user_id: this.currentUser.user_id,
        status_meeting: this.meeting.status,
      };
      const { status } = await Service.createMeetingToRecruitment(params);
      if (status === 200) {
        this.showSuccessSwal();
        this.currentStatus.date_time_meeting = moment(`${this.meeting.date} ${this.meeting.time}`).format('YYYY-MM-DD HH:mm:ss');
        this.showMeeting = false;
        this.currentStatus.status_meeting = ['pending', 'reprogrammed', 'completed'][this.meeting.status - 1];
        this.currentStatus.status_meeting_id = this.meeting.status;

        await this.A_COUNT_MEETINGS_RECRUITMENT({ user_id: null, month: this.currentMonth, year: this.currentYear });
      }
      return status;
    },
  },

};
</script>
