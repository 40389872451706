<template>
  <b-modal
    ref="modal-status-info"
    size="md"
    title="Information of Status"
    :no-close-on-backdrop="true"
    :hide-footer="true"
    @hide="closeModalStatusInfo"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <tabler-icon
          icon="UserPlusIcon"
          size="20"
          class="mr-1"
        />
        <h4 class="m-0 p-0 text-white">
          Information of Status
        </h4>
      </div>
    </template>
    <b-container class="p-1">
      <div
        v-if="!addNewStatus"
        class="d-flex flex-row-reverse mb-1"
      >
        <b-button
          variant="success"
          size="sm"
          @click="newStatus"
        >
          New Status
        </b-button>
      </div>
      <b-card
        title="List of statuses for applicants"
        :style="isDarkSkin ? 'background: #2d3238' : ''"
      >
        <b-col cols="12">
          <b-card
            ref="statusList"
            no-body
            :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
            :style="isDarkSkin ? 'border: .5px solid gray' : 'border: .5px solid #cccccc'"
            style="max-height: 500px; overflow-y: auto;"
          >
            <b-list-group
              class="scrollable-list"
            >
              <b-list-group-item
                v-if="addNewStatus"
                :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
                class="d-flex justify-content-between align-items-center"
              >
                <div class="w-100">
                  <h4>New Status</h4>
                  <b-input
                    v-model="newName"
                    placeholder="Write status name"
                    size="sm"
                    style="width:50%"
                  />
                  <b-input
                    v-model="newDescription"
                    placeholder="Write status description"
                    class="mt-1 w-100"
                    size="sm"
                  />
                  <div
                    class="position-absolute"
                    style="top: 0; right: 0;"
                  >
                    <tabler-icon
                      icon="CircleCheckIcon"
                      size="20"
                      class="text-success"
                      style="cursor:pointer"
                      @click="saveNewEditing()"
                    />
                    <tabler-icon
                      icon="CircleXIcon"
                      size="20"
                      class="text-danger"
                      style="cursor:pointer"
                      @click="stopNewEditing()"
                    />
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-for="(state, key) in optionsStatus"
                :key="key"
                :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
                class="d-flex justify-content-between align-items-center"
              >
                <div v-if="editingState !== state.id">
                  <h4>{{ state.name }}</h4>
                  <h6>{{ state.description }}</h6>
                  <div
                    class="position-absolute"
                    style="top: 0; right: 0;"
                  >
                    <tabler-icon
                      icon="EditIcon"
                      size="20"
                      class="text-success"
                      style="cursor:pointer"
                      @click="startEditing(state.id)"
                    />
                    <tabler-icon
                      v-if="state.id>10"
                      icon="TrashIcon"
                      size="20"
                      class="text-danger"
                      style="cursor:pointer"
                      @click="deleteStatus(state.id)"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="w-100"
                >
                  <b-input
                    v-model="tempName"
                    size="sm"
                    placeholder="Write status name"
                    style="width:50%"
                  />
                  <b-input
                    v-model="tempDescription"
                    placeholder="Write status description"
                    class="mt-1 w-100"
                    size="sm"
                  />
                  <div
                    class="position-absolute"
                    style="top: 0; right: 0;"
                  >
                    <tabler-icon
                      icon="CircleCheckIcon"
                      size="20"
                      class="text-success"
                      style="cursor:pointer"
                      @click="saveEditing(state.id)"
                    />
                    <tabler-icon
                      icon="CircleXIcon"
                      size="20"
                      class="text-danger"
                      style="cursor:pointer"
                      @click="stopEditing()"
                    />
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>

          </b-card>
        </b-col>
      </b-card>

    </b-container>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import Service from '@/views/commons/components/recruitment-process/services/recruiment.service';

export default {
  name: 'ModalStatusInfo',
  data() {
    return {
      optionsStatus: null,
      validationStatus: false,
      tempName: null,
      tempDescription: null,
      editingState: null, // Nueva propiedad para rastrear el estado que se está editando
      addNewStatus: false,
      newDescription: null,
      newName: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.toggleModal('modal-status-info');
    this.getStatus();
  },
  methods: {
    async getStatus() {
      try {
        this.addPreloader();
        const response = await Service.getStatusApplicant();
        this.optionsStatus = response.data.reverse();
        this.removePreloader();
      } catch (error) {
        console.log(error);
      }
    },
    async deleteStatus(stateId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          id: stateId,
          user_id: this.currentUser.user_id,
        };
        const response = await Service.deleteStatusApplicant(params);
        if (response.status === 200) {
          this.removePreloader();
          this.showSuccessSwal('Success');
          this.getStatus();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    async saveEditing(stateId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          id: stateId,
          name: this.tempName,
          description: this.tempDescription,
          user_id: this.currentUser.user_id,
        };
        const response = await Service.editStatusApplicant(params);
        if (response.status === 200) {
          this.removePreloader();
          this.showSuccessSwal('Success');
          this.getStatus();
          this.editingState = null;
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    async saveNewEditing() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          name: this.newName,
          description: this.newDescription,
          user_id: this.currentUser.user_id,
        };
        const response = await Service.saveNewStatusApplicant(params);
        if (response.status === 200) {
          this.removePreloader();
          this.showSuccessSwal('Success');
          this.getStatus();
          this.addNewStatus = false;
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    closeModalStatusInfo() {
      this.$emit('closeModalStatusInfo');
    },
    startEditing(stateId) {
      this.addNewStatus = false;
      const stateToEdit = this.optionsStatus.find(state => state.id === stateId);

      if (stateToEdit) {
        this.tempName = stateToEdit.name;
        this.tempDescription = stateToEdit.description;
      }
      this.editingState = stateId;
    },

    stopEditing() {
      this.editingState = null;
    },

    newStatus() {
      const { statusList } = this.$refs; // Obtén la referencia de tu lista
      statusList.scrollTop = 0;
      this.editingState = null;
      this.addNewStatus = true;
    },
    stopNewEditing() {
      this.addNewStatus = false;
    },
  },
};
</script>
