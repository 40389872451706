<template>
  <div>
    <b-modal
      v-model="onControl"
      :title="'Applicant Status Tracking'"
      title-tag="h3"
      modal-class="modal-primary"
      size="lg"
      hide-footer
      @hidden="close"
    >
      <template #modal-title>
        <div class="d-flex justify-content-start align-items-center text-white">
          <h4 class="m-0 p-0 text-white">
            Applicant Status Tracking "{{ applicant.name }}"
          </h4>
        </div>
      </template>
      <filter-slot
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter="filters"
        :filter-principal="{}"
        :no-visible-principal-filter="true"
        @reload="$refs['refTrackingApplicantTable'].refresh()"
      >
        <template #table>
          <b-table
            ref="refTrackingApplicantTable"
            no-border-collapse
            class="position-relative"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="60vh"
            primary-key="id"
            responsive="sm"
            :items="searchApplicantStatusTracking"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge
                :variant="getVariantColor(data.item.status_num)"
                class="text-center w-100"
              >
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(comment)="data">
              <div v-if="data.item.comment">
                <div v-if="data.item.comment && data.item.comment.length <= 50">
                  {{ data.item.comment }}
                </div>
                <div v-else-if="data.item.comment">
                  <span v-if="!data.item.show_more">{{ data.item.comment.substring(0, 50) }}...</span>
                  <span v-else>{{ data.item.comment }}</span>
                  <br>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="toggleShowFullComment(data.item)"
                  >
                    {{ data.item.show_more ? 'Mostrar menos' : 'Mostrar más' }}
                  </b-button>
                </div>
              </div>
              <div v-else>
                <span>
                  -
                </span>
              </div>
            </template>
            <template #cell(created_at)="data">
              <span>{{ data.item.created_by_name }}</span>
              <br>
              <span>{{ data.item.created_at | myGlobalWithHour }} </span>
            </template>

            <template #cell(updated_at)="data">
              <span>{{ data.item.update_by_name }}</span>
              <br>
              <span>{{ data.item.updated_at | myGlobalWithHour }} </span>
            </template>

          </b-table>
        </template>
      </filter-slot>

    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import RecruitmentPService from '@/views/commons/components/recruitment-process/services/recruiment.service';

export default {

  props: {
    applicant: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      onControl: false,
      filterPrincipal: [],
      fields: [
        {
          key: 'status',
          sortable: false,
          label: 'Status',
          visible: true,
        },
        {
          key: 'comment',
          sortable: false,
          label: 'Note / Comment',
          visible: true,
        },
        {
          key: 'created_at',
          sortable: false,
          label: 'Created by',
          visible: true,
        },
        {
          key: 'updated_at',
          sortable: false,
          label: 'Updated by',
          visible: true,
        },
      ],
      filters: [],

      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: 'created_at',
      sortDesc: true,
      searchInput: '',
      isBusy: false,
      trackingItems: [],
      showFullComment: false,
    };
  },
  async created() {
    console.log(this.applicant);
    this.onControl = true;
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    toggleShowFullComment(item) {
      const foundItem = this.trackingItems.find(trackingItem => trackingItem.status_num === item.status_num);
      if (foundItem) {
        foundItem.show_more = !item.show_more;
      }
    },
    close() {
      this.$emit('close');
    },
    async searchApplicantStatusTracking(ctx) {
      const response = await RecruitmentPService.getApplicantStatusTracking({
        page: ctx.currentPage,
        perPage: ctx.perPage,
        applicant_id: this.applicant.id,
      });
      const trackingItemsWithShowMore = response.data.data.map(item => ({
        ...item,
        show_more: false,
      }));
      this.trackingItems = trackingItemsWithShowMore;
      this.startPage = response.data.from;
      this.paginate.currentPage = response.data.current_page;
      this.paginate.perPage = response.data.per_page;
      this.totalRows = response.data.total;
      this.toPage = response.data.to;
      return this.trackingItems || [];
    },
    getVariantColor(status) {
      switch (status) {
        case 1:
          return 'light-primary';
        case 2:
          return 'light-success';
        case 3:
          return 'light-danger';
        case 4:
          return 'light-info';
        case 5:
          return 'success';
        case 6:
          return 'light-info';
        case 7:
          return 'light-warning';
        default:
          return 'light-info';
      }
    },
    getVariantCategory(status) {
      switch (status) {
        case 1:
          return 'light-primary';
        case 2:
          return 'light-warning';
        case 3:
          return 'light-success';
        default:
          return 'light-info';
      }
    },
  },
};
</script>

<style scoped>

</style>
